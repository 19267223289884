<template>
  <cgu />
</template>

<script>
export default {
  name: 'mobility-cgu',
  components: {
    Cgu: () => import('@/pages/cgu')
  }
}
</script>
